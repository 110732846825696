<template>

<div>

    <table>
        <tr>
            <th>1</th>
            <th>2</th>
        </tr>
        <tr v-for="item in data" :key="item.datetime">
            <td>{{ item.datetime }}</td>
            <td>{{ item.price }}</td>
        </tr>
    </table>

</div>

</template>

<script>
import axios from 'axios';

export default {
    name: 'PriceTable',
    data() {
        return {
            data: null,
            days: 10,
        }
    },
    mounted() {
         this.getData()
    },
    methods: {
        getData() {
            axios.get(`https://credizila.com.br/api?days=${this.days}&type=hour`)
            .then(response => {
                console.log(response)
                // this.data = response.data.reverse();
                console.log(this.data)
            })
            .catch(error => {
                console.log(error);
            })
        }
    }
}

</script>