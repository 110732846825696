<template>
  <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <!-- <PriceChart></PriceChart> -->
    <!-- <PriceChartDay></PriceChartDay> -->
    <PriceTable></PriceTable>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
// import PriceChart from '@/components/Charts/PriceChart.vue'
// import PriceChartDay from '@/components/Charts/PriceChartDay.vue';
import PriceTable from '@/components/Charts/PriceTable.vue';

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    PriceTable
    // PriceChart,
    // PriceChartDay
  }
}
</script>
